export const EN_US = {
  "app.menu.feed": "Feed",
  "app.menu.messages": "Messages",
  "app.menu.announcements": "Announcements",
  "app.menu.residents": "Residents",
  "app.menu.authorizations": "Authorizations",
  "app.menu.deliveries": "Deliveries",
  "app.menu.reservations": "Reservations",
  "app.menu.settings": "Settings",
  "app.menu.notifications": "Notifications",
  "app.navbar.search": "Search",
  "app.menu.marketplace": "Marketplace",
  "post.input.defaultText": "Say hi to your neighbors!",
  "post.input.publish": "Publish",
  "post.menu.remove": "Remove",
  "post.menu.update": "Update",
  "app.feed.noposts.first": "Oh, there's nothing here.",
  "app.feed.noposts.second": "Try saying hi to your neighbors!",
  "post.navigation.back": "Back",
  "post.input.submit": "Update",
  "modal.delete.cta": "Delete",
  "modal.delete.cancel": "Cancel",
  "modal.delete.title": "Are you sure?",
  "modal.delete.text": "This action can not be reverted.",
  "app.feed.createPost.success": "Post created",
  "app.feed.createPost.error": "An error occurred when trying to create this post",
  "app.feed.deletePost.success": "Post deleted",
  "app.feed.deletePost.error": "An error occurred when trying to delete this post",
  "app.feed.updatePost.success": "Post updated",
  "app.feed.updatePost.error": "An error occurred when trying to update this post",
  "app.feed.fetchPosts.error": "An error occurred when trying to fetch your feed",
};
