const BOX = {
  CARD: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
  BUTTON: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
};

const SHADOWS = {
  BOX,
};

export default SHADOWS;
