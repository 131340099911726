const SIZES = {
  TABLET: "1145px",
  MOBILE: "850px",
};

const SCREEN = {
  SIZES,
};

export default SCREEN;
